import React, { useCallback, useState } from 'react'
import { Field, FormikProps, useFormikContext } from 'formik';
import AnimateHeight from 'react-animate-height';
import FormLabel from 'ui/FormLabel';
import classnames from 'classnames';
import { Multiselect } from 'ui/Multiselect';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';
import { theme } from '../../../../../tailwind.config';
import { ArrowDownComponent } from 'ui/Icons';
import { ICompanyProfileFormValues } from 'containers/CRM/types/ICompanyProfile';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import Checkbox from 'ui/Checkbox';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { FormDecimalInput } from 'pureUi/FormDecimalInput';
import { travelCompanyTypeOptions } from 'services/BackendApi/utils/companyHelpers';

const productOptions = [
  {
    label: 'HOTEL',
    value: 'Hotel',
  },
  {
    label: 'ACTIVITIES',
    value: 'Activities',
  },
  {
    label: 'TRANSFERS',
    value: 'Transfers',
  },
  {
    label: 'GROUND SERVICES',
    value: 'Ground Services',
  }
]

export const ProfileInfo = () => {
  const form: FormikProps<ICompanyProfileFormValues> = useFormikContext();
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const [isProfileInfoOpen, setProfileInfoOpen] = useState(isDesktop || isDesktopHD);

  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const typeOptions: ISingleSelectOption[] = crmTypeValuesCompanies.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const crmCountries = useCrmCountries();
  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];
  
  const isSr = useSelector(AuthSelectors.isSr);
  const canEdit = !isSr;

  const profileInfoClassname = classnames('profile-info-container border border-solid border-gray-40 rounded-[4px]', {
    'min-w-[320px]': isDesktop || isDesktopHD,
  })
  
  const toggleProfileInfo = useCallback(() => {
    if (isMobile || isTablet) {
      setProfileInfoOpen(!isProfileInfoOpen);
    }
  }, [isMobile, isProfileInfoOpen, isTablet]);

    return (
      <div className={profileInfoClassname}>
          <div className="page-section-title font-pt-sans flex justify-between items-baseline p-[20px]" onClick={toggleProfileInfo}>
            <h3 className="section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0 select-none">
              Profile Info
            </h3>
            {(isMobile || isTablet) && (
              <span
                className={classnames(
                  'flex justify-center items-center transform-transition transition-ease-in-out duration-300 cursor-pointer',
                  { 'rotate-180': isProfileInfoOpen }
                )}
              >
                <ArrowDownComponent icon={theme.colors['black']} />
              </span>
            )}
          </div>
          <AnimateHeight duration={300} height={isProfileInfoOpen ? 'auto' : 0} className="accordion-area">
            <>
              <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] px-[20px] pb-[20px]">
                  <Field name="type">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="type"
                        label="Type *"
                        className="type"
                        value={value}
                        showSelectedOption
                        maxVisibleItems={isMobile ? 4 : 6}
                        scrollToSelectedItem
                        options={typeOptions ?? []}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={!canEdit}
                        errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                        errorClassName="type-error"
                      />
                    )}
                  </Field>
                  <Field
                    as={TextInput}
                    type="text"
                    name="name"
                    id="name"
                    className="name flex-1 w-full desktop:max-w-[480px]"
                    label="Name *"
                    disabled={!canEdit}
                    errorMessage={form.touched.name && form.errors.name ? form.errors.name : null}
                    errorClassName="name-error"
                  />
                  <Field name="travelCompanyType">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="travel-company-type"
                        label="Travel Company Type"
                        className="travel-company-type"
                        value={value}
                        showSelectedOption
                        scrollToSelectedItem
                        maxVisibleItems={isMobile ? 4 : 6}
                        options={travelCompanyTypeOptions ?? []}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        disabled={!canEdit}
                        errorMessage={form.touched.travelCompanyType && form.errors.travelCompanyType ? form.errors.travelCompanyType : null}
                        errorClassName="travel-company-type-error"
                      />
                    )}
                  </Field>
                  <Field name="products">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <FormLabel className="products flex-1 " text="Products">
                        <Multiselect
                          className="bg-ivory w-full"
                          itemsClassname="bg-ivory"
                          itemCtaClassName="hover:bg-gray-20"
                          options={productOptions}
                          onUpdate={sv => {
                            setFieldValue(name, sv);
                          }}
                          selectedValues={value}
                          disabled={!canEdit}
                          />
                      </FormLabel>
                    )}
                  </Field>
                  <Field
                    as={TextInput}
                    type="text"
                    name="industry"
                    id="industry"
                    className="industry flex-1 w-full desktop:max-w-[480px]"
                    label="Industry"
                    disabled={!canEdit}
                    errorMessage={form.touched.industry && form.errors.industry ? form.errors.industry : null}
                    errorClassName="industry-error"
                  />
                  <Field
                    as={FormDecimalInput}
                    decimalPlaces={0}
                    name="employeeCount"
                    id="employee-count"
                    className="employee-count flex-1 w-full desktop:max-w-[480px]"
                    label="Employee Count"
                    disabled={!canEdit}
                    errorMessage={form.touched.employeeCount && form.errors.employeeCount ? form.errors.employeeCount : null}
                    errorClassName="employeeCount-error"
                  />
                  <Field name="country">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="country"
                        label="Country"
                        className="country"
                        value={value ?? null}
                        maxVisibleItems={isMobile ? 4 : 6}
                        showSelectedOption
                        scrollToSelectedItem
                        disabled={!canEdit}
                        options={countryOptions ?? []}
                        labelWhenNothingSelected="None"
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    as={TextInput}
                    type="text"
                    name="address"
                    id="address"
                    className="address flex-1 w-full desktop:max-w-[480px]"
                    label="Address"
                    disabled={!canEdit}
                    errorMessage={form.touched.address && form.errors.address ? form.errors.address : null}
                    errorClassName="address-error"
                  />
                  <Field
                    as={TextInput}
                    type="text"
                    name="phone"
                    id="phone"
                    className="phone flex-1 w-full desktop:max-w-[480px]"
                    label="Phone"
                    disabled={!canEdit}
                    errorMessage={form.touched.phone && form.errors.phone ? form.errors.phone : null}
                    errorClassName="phone-error"
                  />
                  <Field
                    as={TextInput}
                    type="text"
                    name="email"
                    id="email"
                    className="email flex-1 w-full desktop:max-w-[480px]"
                    label="Email"
                    disabled={!canEdit}
                    errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                    errorClassName="email-error"
                  />
                  <Field name="acceptsEmail">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <label
                        className={classnames('flex justify-start items-center cursor-pointer mr-5px')}
                        onClick={() => setFieldValue(name, !value)}
                      >
                        <Checkbox
                          checked={value}
                          // onClick={} - handled by the label above
                          className={classnames('cursor-pointer')}
                          disabled={!canEdit}
                          />
                        <span className={classnames('checkbox-label font-pt-sans text-[16px] leading-[21px] text-black ml-2')}>
                          Accepts receive info by email
                        </span>
                      </label>
                    )}
                  </Field>
                  <Field
                    as={TextInput}
                    type="text"
                    name="website"
                    id="website"
                    className="website flex-1 w-full desktop:max-w-[480px]"
                    label="Website"
                    disabled={!canEdit}
                    errorMessage={form.touched.website && form.errors.website ? form.errors.website : null}
                    errorClassName="website-error"
                  />
                  <Field
                    as={TextInput}
                    type="text"
                    name="linkedin"
                    id="linkedin"
                    className="linkedin flex-1 w-full desktop:max-w-[480px]"
                    label="Linkedin"
                    disabled={!canEdit}
                    errorMessage={form.touched.linkedin && form.errors.linkedin ? form.errors.linkedin : null}
                    errorClassName="linkedin-error"
                  />
                  <Field
                    as={TextInput}
                    type="text"
                    name="twitter"
                    id="twitter"
                    className="twitter flex-1 w-full desktop:max-w-[480px]"
                    label="X"
                    errorMessage={form.touched.twitter && form.errors.twitter ? form.errors.twitter : null}
                    errorClassName="twitter-error"
                  />
                  <Field
                    as={TextInput}
                    type="text"
                    name="instagram"
                    id="instagram"
                    className="instagram flex-1 w-full desktop:max-w-[480px]"
                    label="Instagram"
                    disabled={!canEdit}
                    errorMessage={form.touched.instagram && form.errors.instagram ? form.errors.instagram : null}
                    errorClassName="instagram-error"
                  />
              </div>

            </>
          </AnimateHeight>
        </div>
    )
}