import React, { useCallback } from 'react';
import classnames from 'classnames';
import { GeneralModal } from 'ui/GeneralModal';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { GA_FLOW_TAG } from './AddAccommodationModalFilters';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';
import { AccommodationRightHandPanel } from './AccommodationRightHandPanel';
import { EBAMPage } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { LHPPageAccommodations } from './LeftHandPanel/LHPPageAccommodations';
import { LHPPageAddons } from './LeftHandPanel/LHPPageAddons';

export interface IAddAccommodationModalProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

export const AddAccommodationModal = ({ bookingUuid, bookingCurrencySymbol }: IAddAccommodationModalProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const isAccommodationModalOpen = useSelector(BreakdownSelectors.breakdownAncillaryAccommodationModalToggleSelector);
  const hotelUuid = useSelector(BreakdownSelectors.BAMHotelFilterSelector);
  const selectedBuild = useSelector(BreakdownSelectors.BAMSelectedBuildSelector);
  const showRightPanel = selectedBuild !== null;

  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const error = useSelector(BreakdownSelectors.BAMErrorSelector);

  const page = useSelector(BreakdownSelectors.BAMPageSelector);

  const handleCloseModal = useCallback(() => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_close`, {});
    }
    dispatch(BreakdownActions.setAddAncillaryAccommodationModalToggleAction(false));
    dispatch(BreakdownActions.resetBAMStateAction());
  }, [dispatch, dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID]);

  if (!isAccommodationModalOpen) {
    return null;
  }

  if (!topNavigationData) {
    return (
      <GeneralModal
        onClose={handleCloseModal}
        modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
        shouldCloseByClickingOutside={false}
      >
        <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>
        <div>
          <LoadingBar />
        </div>
      </GeneralModal>
    );
  }

  if (error || !bookingCurrencySymbol) {
    return (
      <GeneralModal
        onClose={handleCloseModal}
        modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
        shouldCloseByClickingOutside={false}
      >
        <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>
        <div>
          <ErrorBar message={error ?? 'Failed to load content'} />
        </div>
      </GeneralModal>
    );
  }

  return (
    <GeneralModal
      onClose={handleCloseModal}
      modalWindowClassName="w-[1200px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>

      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div className="left-panel flex flex-col flex-[601]  space-y-25px">
          <LHPPageAccommodations
            hotelUuid={hotelUuid}
            currencySymbol={bookingCurrencySymbol}
            bookingUuid={bookingUuid}
            isVisible={page === EBAMPage.PAGE_ACCOMMODATIONS}
          />
          <LHPPageAddons
            hotelUuid={hotelUuid}
            currencySymbol={bookingCurrencySymbol}
            bookingUuid={bookingUuid}
            isVisible={page === EBAMPage.PAGE_ADDONS}
          />
        </div>

        {showRightPanel && (
          <AccommodationRightHandPanel
            currencySymbol={bookingCurrencySymbol}
            selectedBuild={selectedBuild}
            loadingStatus={ENetworkRequestStatus.SUCCESS}
            bookingUuid={bookingUuid}
            topNavigationData={topNavigationData}
          />
        )}
      </div>
    </GeneralModal>
  );
};
