import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import classnames from 'classnames';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { TextInput } from 'ui/TextInput';
import Editor from 'pureUi/Editor';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { VerticalSpace } from 'ui/VerticalSpace';
import FluidButton from 'ui/FluidButton';
import { useHistory, useRouteMatch } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { useNotifications } from 'hooks/useNotifications';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';
import { ProfileInfo } from '../components/ProfileInfo/ProfileInfo';
import { ICompanyProfileFormValues } from '../types/ICompanyProfile';
import { ECrmDirectoryEntryType, ICrmCompanyProfile } from 'services/CrmApi/types/DirectoryEntry';
import { CrmCompanyTabs } from '../components/CrmCompanyTabs/CrmCompanyTabs';
import { CompanyPageTitle } from '../components/CompanyPageTitle';

interface ICrmCompanyEditRouteParams {
  companyId: string;
}

export const CrmCompanyEdit = () => {
  const history = useHistory();
  const match = useRouteMatch<ICrmCompanyEditRouteParams>();
  const companyId = match.params.companyId;
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const { showSuccessNotification } = useNotifications();

  const crmCountries = useCrmCountries();
  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const directoryEntry = useCrmDirectoryEntry(companyId);
  const directoryEntryData = directoryEntry.data?.data;
  const profile = directoryEntryData?.profile as ICrmCompanyProfile;

  const layoutClassname = classnames('flex gap-[20px]', {
    'flex-col': isMobile || isTablet,
    'flex-row': isDesktop || isDesktopHD,
  });

  const initialValues: ICompanyProfileFormValues = useMemo(() => {
    return {
      id: directoryEntryData?.id ?? '',
      type: profile?.companyType?.code ?? '',
      name: directoryEntryData?.fullName ?? '',
      travelCompanyType: profile?.tcType ?? '',
      products: [],
      industry: profile?.industry ?? '',
      employeeCount: profile?.employeeCount ?? '',
      country: directoryEntryData?.country?.code ?? null,
      address: directoryEntryData?.address ?? '',
      phone: directoryEntryData?.mobile ?? '',
      email: directoryEntryData?.email ?? '',
      acceptsEmail: directoryEntryData?.acceptsEmail ?? false,
      website: profile?.website ?? '',
      linkedin: directoryEntryData?.linkedin ?? '',
      twitter: directoryEntryData?.twitter ?? '',
      instagram: directoryEntryData?.instagram ?? '',
      manager: directoryEntryData?.manager.id,
    }
  }, [directoryEntryData, profile]);

  const handleFormSubmit = useCallback(async (values: ICompanyProfileFormValues, formikHelpers: FormikHelpers<ICompanyProfileFormValues>) => {

  }, []);

  if (crmCountries.isPending || crmTypeValuesCompanies.isPending || directoryEntry.isPending || !directoryEntryData) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesCompanies.isError) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }
 
  if (directoryEntryData.type !== ECrmDirectoryEntryType.COMPANY) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a company profile" />
      </div>
    )
  }
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <CompanyPageTitle
        primaryText={directoryEntryData?.fullName ?? ''}
        countryCode={directoryEntryData?.country?.code}
        directoryEntry={directoryEntry.data?.data}
        companyType={profile.companyType.value}
      />
      <VerticalSpace height="20px" />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        <div className={layoutClassname}>
          <ProfileInfo  />
          <CrmCompanyTabs />
        </div>
      </Formik>
    </div>
  )
};
