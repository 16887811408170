import React, { useCallback, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import classnames from 'classnames';
import UserIcon from 'ui/Icons/person-alt.component.svg';
import { formatDateDisplayFourDigitYear, numberOfNights } from 'utils';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { isNil, sortBy } from 'lodash-es';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';

import {
  BookingBuilderResponse,
  HotelRoomSearchBuild,
  IHotelAccommodationsSearchRequest,
  makeBackendApi,
} from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import {
  ISimulateBreakdownRequest,
  ISimulateBreakdownResponse,
  ITopNavigationData,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import { AddAncillaryRightHandPanelTotalCost } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelTotalCost';
import { EBAMPage } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { EditButton } from 'ui/EditButton/EditButton';
import { RHPPageAddons } from './RightHandPanel/RHPPageAddons';
import { RHPPageAccommodations } from './RightHandPanel/RHPPageAccommodations';
import { RHPOffers } from './RightHandPanel/RHPOffers';
import { enqueueNotification } from 'store/modules/ui';
import { RHPServiceCharge } from './RightHandPanel/RHPServiceCharge';
import { useApiRequest } from 'hooks/useApiRequest';
import { AxiosResponse } from 'axios';

const GuestAges: React.FC<{ lastSearchRequest: IHotelAccommodationsSearchRequest }> = ({ lastSearchRequest }) => {
  return (
    <div className="guest-ages flex items-center mt-[5px]">
      <UserIcon className="w-[16px] h-[16px] stroke-black fill-black" />
      <span className="ml-[5px]">x{lastSearchRequest?.numberOfAdults}</span>
      {lastSearchRequest?.agesOfAllChildren && lastSearchRequest.agesOfAllChildren.length >= 1 && (
        <>
          <UserIcon className="w-[12px] h-[12px] stroke-black fill-black" />
          <span className="ml-[5px]">x{lastSearchRequest.agesOfAllChildren.length}</span>
          <span>&nbsp;({sortBy(lastSearchRequest.agesOfAllChildren).join(', ')}&nbsp;years)</span>
        </>
      )}
    </div>
  );
};

export interface IAccommodationRightHandPanelProps {
  selectedBuild: HotelRoomSearchBuild;
  currencySymbol: string;
  bookingUuid: string;
  topNavigationData: ITopNavigationData;
  loadingStatus: ENetworkRequestStatus;
}

export const AccommodationRightHandPanel: React.FC<IAccommodationRightHandPanelProps> = ({
  currencySymbol,
  selectedBuild,
  loadingStatus,
  topNavigationData,
  bookingUuid,
}) => {
  const dispatch = useDispatch();

  const lastSearchRequest = useSelector(BreakdownSelectors.BAMLastRequestSelector);
  const startDate = useSelector(BreakdownSelectors.BAMStartDateFilterSelector);
  const endDate = useSelector(BreakdownSelectors.BAMEndDateFilterSelector);
  const bookingBuildRequest = useSelector(BreakdownSelectors.BAMBookingBuildRequestSelector);
  const countryData = useSelector(BreakdownSelectors.BAMCountryDataSelector);
  const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const selectedMealPlan = selectedAccommodation?.availableSubProductSets['Meal Plan'].find(item => item.selected);
  const rightPanelClassname =
    'right-panel font-pt-sans border-solid border-1 border-gray-40 rounded-[4px] p-20px flex-[260] flex flex-col justify-between w-[321px] space-y-20px';
  const page = useSelector(BreakdownSelectors.BAMPageSelector);

  const bookingBuilderErrors = selectedBuild.errors;

  const headlineLineItemBreakdown = useSelector(BreakdownSelectors.headlineLineItemBreakdownSelector);

  const cancellationPolicies = useSelector(BreakdownSelectors.cancellationPoliciesSelector)!;
  const paymentTerms = useSelector(BreakdownSelectors.paymentTermsSelector)!;
  const policiesAndRestrictions = useSelector(BreakdownSelectors.policiesAndRestrictionsSelector)!;
  const offerTerms = useSelector(BreakdownSelectors.offerTermsSelector)!;

  const latestRequestedBuild = useSelector(BreakdownSelectors.BAMLastRequestedBuildSelector);
  const handleContinueButton = useCallback(() => {
    dispatch(BreakdownActions.setBAMPageAction(EBAMPage.PAGE_ADDONS));
  }, [dispatch]);

  const bookingManagerApi = makeBookingManagerApi();

  const postSimulateBreakdown = useApiRequest(bookingManagerApi.postSimulateBreakdown);

  const handleAddAccommodationButton = useCallback(async () => {
    if (!headlineLineItemBreakdown || !selectedBuild || !latestRequestedBuild || !countryData) {
      return;
    }

    const simulateBreakdownRequest: ISimulateBreakdownRequest = {
      bookingBuild: selectedBuild,
      requestedBuild: latestRequestedBuild,
      userCountryCode: countryData,
      headlineLineItemBreakdown,
      cancellationPolicies,
      paymentTerms,
      policiesAndRestrictions,
      offerTerms,
    };

    try {
      const res = (await postSimulateBreakdown.execute(bookingUuid, simulateBreakdownRequest)) as AxiosResponse<
        ISimulateBreakdownResponse
      >;

      dispatch(
        BreakdownActions.updateBreakdownDataAfterAddOrEditAccommodation(
          res.data.headlineLineItemBreakdown,
          res.data.cancellationPolicies,
          res.data.paymentTerms,
          res.data.policiesAndRestrictions,
          res.data.offerTerms
        )
      );

      dispatch(
        enqueueNotification({
          message: 'Accommodation added successfully.',
          options: { variant: 'success' },
        })
      );
      dispatch(BreakdownActions.setAddAncillaryAccommodationModalToggleAction(false));
      dispatch(BreakdownActions.resetBAMStateAction());
    } catch (e) {
      dispatch(
        enqueueNotification({
          message: 'Accommodation failed to add. Please try again.',
          options: { variant: 'error' },
        })
      );
      console.error('Error', e);
    }
  }, [dispatch, selectedBuild, latestRequestedBuild]);

  const handleEdit = useCallback(() => {
    dispatch(BreakdownActions.resetBAMPageAddonsAction());
    dispatch(BreakdownActions.bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid]);

  if (!selectedAccommodation) {
    return (
      <div className={rightPanelClassname}>
        <ErrorBar message="Something went wrong" />
      </div>
    );
  }

  const accomErrors = bookingBuilderErrors.filter(e => e.meta === 'Accommodation');
  const transferErrors = bookingBuilderErrors.filter(e => e.meta === 'Transfer');
  const groundServiceErrors = bookingBuilderErrors.filter(e => e.meta === 'Ground Service');
  const supplementErrors = bookingBuilderErrors.filter(e => e.meta === 'Supplement');
  const fineErrors = bookingBuilderErrors.filter(e => e.meta === 'Fine');

  const isLiveRate = selectedAccommodation.breakdown.some(item => item.isLiveRate);

  return (
    <div className={rightPanelClassname}>
      {loadingStatus === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {loadingStatus === ENetworkRequestStatus.ERROR && <ErrorBar />}

      {loadingStatus === ENetworkRequestStatus.SUCCESS && !isNil(selectedAccommodation) && (
        <React.Fragment>
          <div
            className={classnames('your-choice rhp-top-part flex flex-col ', {
              'opacity-50': bookingBuildRequest === ENetworkRequestStatus.PENDING,
            })}
          >
            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-100 uppercase">Your Choice</span>
              {page === EBAMPage.PAGE_ADDONS && <EditButton caption="Edit" onEdit={handleEdit} />}
            </div>
            <span className="mt-[5px] text-17px leading-22px text-black font-bold">
              {topNavigationData.hotelName} - {selectedAccommodation.products[0].name}
            </span>
            {lastSearchRequest && <GuestAges lastSearchRequest={lastSearchRequest} />}

            <span className="dates flex mt-[5px] justify-between items-center space-x-2 w-full">
              <div className="flex items-center gap-[5px]">
                <SvgIcon width="16px" height="16px" IconComponent={CalendarIcon} defaultFill="#413E3B" />
                <span>
                  {formatDateDisplayFourDigitYear(startDate)} - {formatDateDisplayFourDigitYear(endDate)}
                </span>
              </div>
              <span className="flex justify-center items-center bg-teal-40 px-[10px] h-[27px]">
                {numberOfNights(startDate, endDate)}&nbsp;N
              </span>
            </span>

            {page === EBAMPage.PAGE_ACCOMMODATIONS && (
              <RHPPageAccommodations
                selectedBuild={selectedBuild}
                currencySymbol={currencySymbol}
                bookingBuildRequest={bookingBuildRequest}
                bookingUuid={bookingUuid}
                errors={accomErrors}
              />
            )}
            {page === EBAMPage.PAGE_ADDONS && (
              <RHPPageAddons
                selectedBuild={selectedBuild}
                currencySymbol={currencySymbol}
                bookingBuildRequest={bookingBuildRequest}
                bookingUuid={bookingUuid}
                transferErrors={transferErrors}
                groundServiceErrors={groundServiceErrors}
                supplementErrors={supplementErrors}
                fineErrors={fineErrors}
              />
            )}
          </div>

          {bookingBuildRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {bookingBuildRequest !== ENetworkRequestStatus.PENDING && (
            <div className="rhp-bottom-part space-y-10px pt-10px">
              <RHPOffers selectedBuild={selectedBuild} />

              <RHPServiceCharge currencySymbol={currencySymbol} selectedBuild={selectedBuild} />

              <AddAncillaryRightHandPanelTotalCost
                price={selectedBuild.totals.total}
                isOnRequest={selectedBuild.totals.oneOrMoreItemsOnRequest}
                currencySymbol={currencySymbol}
              />

              {page === EBAMPage.PAGE_ACCOMMODATIONS && (
                <FluidButton onClick={handleContinueButton} className="block w-full" type="primary">
                  Continue &gt;
                </FluidButton>
              )}
              {page === EBAMPage.PAGE_ADDONS && (
                <div className="flex flex-col gap-4 mt-20px">
                  {isLiveRate && (
                    <span className="text-yellow-60 font-pt-sans text-13px leading-16px">
                      NOTE: After BOOK NOW in Breakdown, we will check to ensure that availability and price has not
                      changed
                    </span>
                  )}
                  <FluidButton
                    isLoading={postSimulateBreakdown.requestStatus === ENetworkRequestStatus.PENDING}
                    onClick={handleAddAccommodationButton}
                    className="block w-full"
                    type="primary"
                  >
                    Add Accommodation
                  </FluidButton>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
