import { useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosResponse } from "axios";
import { IDirectoryEntry } from "../types/DirectoryEntry";

export const useCrmDirectoryEntry = (directoryId: string) => {
  const crmApi = makeCRMApi();

  const crmDirectoryEntry = useQuery<AxiosResponse<IDirectoryEntry>>({
    queryKey: ['crm-directory-entries', directoryId],
    queryFn: () =>
      crmApi.getDirectory(directoryId),
  });

  return crmDirectoryEntry;
}
