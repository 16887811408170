import { ICompanyProfileFormValues } from 'containers/CRM/types/ICompanyProfile';
import classnames from 'classnames';
import { Field, FormikProps, useFormikContext } from 'formik';
import * as AuthSelectors from 'store/modules/auth/selectors';
import Editor from 'pureUi/Editor';
import React from 'react';
import FluidButton from 'ui/FluidButton';
import { useSelector } from 'react-redux';
import { useBEUsers } from 'services/BackendApi/queries/useBEUsers';
import { userToOption } from 'ui/SingleSelect/helpers';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { LoadingBar } from 'ui/NetworkStatusBar';

export const CrmCompanyMainInfo = () => {
    const { isMobile } = useResponsive();
    const form: FormikProps<ICompanyProfileFormValues> = useFormikContext();
    const saveButtonClassname = classnames('');
  
    const isSr = useSelector(AuthSelectors.isSr);
    const canEdit = !isSr;
    const beUsers = useBEUsers('');
    const managerOptions: ISingleSelectOption[] = beUsers?.data?.data?.data
      ?.map(user => userToOption(user)) ?? [];

    if (beUsers.isPending) {
      return (
        <div className="w-full flex justify-center mt-[20px]">
          <LoadingBar />
        </div>
      )
    }
    
    return (
      <div className="main-info-container w-full px-[10px] pt-[15px]">
        <h3 className="section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0">
          Main Info
        </h3>
        <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] ">
          <Field name="manager">
            {({ field: { name, value }, form: { setFieldValue } }) => (
              <SingleSelect
                fieldId="manager"
                label="Manager/Owner *"
                className="manager"
                value={value}
                showSelectedOption
                maxVisibleItems={isMobile ? 4 : 6}
                scrollToSelectedItem
                options={managerOptions ?? []}
                onChange={value => {
                  setFieldValue(name, value);
                }}
                disabled={!canEdit}
                errorMessage={form.touched.manager && form.errors.manager ? form.errors.manager : null}
                errorClassName="manager-error"
              />
            )}
          </Field>
          <div className="key-notes flex  gap-[20px]">
            <Field name="keynotes">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <div className="flex flex-col w-full">
                  <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                    Key Notes
                  </label>
                  <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} disabled={!canEdit} />
                </div>
              )}
            </Field>
          </div>

          <div className="buttons-container ">
            <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className={saveButtonClassname} isLoading={false} disabled={false}>
                Save
            </FluidButton>
          </div>
        </div>
      </div>
    );
}