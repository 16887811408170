import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { roundToDecimalPlaces } from 'utils';

export type InputMode = 'none' | 'text' | 'Standard' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
export type TextTransform = 'inherit' | 'uppercase'; // add more as needed

export interface IFormDecimalInputProps {
  value: string | number;
  className?: string;
  onBlur: (updatedValue: number | null) => void;
  decimalPlaces: number;
  disabled?: boolean;
  max?: number;
  min?: number;
  allowNull?: boolean;
  validRegex?: RegExp;
  label?: string;
  id: string;
  labelClassName?: string;
  inputClassName?: string;
  labelStyle?: React.CSSProperties;
  errorMessage?: string | null;
  errorClassName?: string;
  warning?: boolean;
}

export const FormDecimalInput = (props: IFormDecimalInputProps) => {
  const { value, allowNull = false } = props;
  const { disabled = false } = props;

  const [localValue, setLocalValue] = useState(props.value);
  const isError = !!props.errorMessage || props.errorMessage === '';
  const inputClassname = classnames('default:text-15px default:leading-19px py-7px px-10px default:mt-5px default:font-pt-sans outline-none default:placeholder:italic', props.inputClassName,
    {
      "default:border-gray-40 default:bg-ivory": !isError && !props.disabled,
      "border-red-95 bg-red-25": isError && !props.disabled,
      "border-yellow-30": !!props.warning,
      "text-black border border-solid": !props.disabled,
      "default:text-gray-100 border border-gray-20 bg-ivory": props.disabled,
    }
  )
  const handleOnChange = useCallback(event => {
    const validRegex = props.validRegex || /^[0-9.]*$/;
    if (!validRegex.test(event.target.value)) {
      return;
    }
    setLocalValue(event.target.value);
  }, [props.validRegex]);

  const handleOnBlur = useCallback(() => {
    if ((localValue === '' || localValue === null) && allowNull) {
      props.onBlur(null);
      setLocalValue('');
      return;
    }
    let val = parseFloat(String(localValue));
    if (isNaN(val)) {
      val = 0;
    }

    if (props.min && val < props.min) {
      val = props.min;
    }

    if (props.max && val > props.max) {
      val = props.max;
    }

    val = roundToDecimalPlaces(val, props.decimalPlaces); // round the number...
    val = parseFloat(val.toFixed(props.decimalPlaces)); //...then toFixed it afterwards, as toFixed rounding is broke

    setLocalValue(val);
    props.onBlur(val);
  }, [allowNull, localValue, props]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={`flex flex-col ${props.className ?? ''}`}>
      {props.label && (
        <label
          style={props.labelStyle}
          className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs', props.labelClassName)}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <input
        className={inputClassname}
        value={localValue}
        id={props.id}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled}
      />
      {props.errorMessage && (
        <p className={`text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ${props.errorClassName ?? ''}`}>
          {props.errorMessage}
        </p>
      )}
    </div>
  );
};

